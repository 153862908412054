import React from "react";
import { Button } from "primereact/button";
import { AiOutlineSwap } from "react-icons/ai";
import currencyData from "../../Currency/currency.json";

const BuyPostRow = ({ post, buyFromPost }) => {
  const currencyPair = (data) => {
    const fromCurrency = data?.primary_currency
      ? currencyData.find((e) => e.code === data.primary_currency)
      : null;
    const toCurrency = data?.secondary_currency
      ? currencyData.find((e) => e.code === data.secondary_currency)
      : null;

    return (
      <div className="flex flex-row flagsExch">
        <img
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flagSetup flag ${
            toCurrency ? `flag-${toCurrency.countryCode.toLowerCase()}` : ""
          }`}
          alt={data.secondary_currency}
        />
        <div className="item iconWrap">
          <AiOutlineSwap id="swapIcon" />
        </div>
        <img
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flagSetup flag ${
            fromCurrency ? `flag-${fromCurrency.countryCode.toLowerCase()}` : ""
          }`}
          alt={data.primary_currency}
        />
      </div>
    );
  };

  const availableAmount = (data) => {
    const traded = 0;
    const total = data?.amount || 0;
    return (
      <span>
        {(total - traded).toFixed(2)} {data.from}
      </span>
    );
  };

  const exchangeRate = (data) => {
    return (
      <span>
        {data?.rate
          ? `${data.rate.toFixed(4)} ${data.secondary_currency} per ${
              data.primary_currency
            }`
          : "N/A"}
      </span>
    );
  };

  return (
    <div className="custom-table-row">
      <div>{currencyPair(post)}</div>
      <div>{exchangeRate(post)}</div>
      <div>{availableAmount(post)}</div>
      <div>
        <Button onClick={() => buyFromPost(post)} text>
          Buy
        </Button>
      </div>
    </div>
  );
};

export default BuyPostRow;
