// orderReducer.js

import {
  FETCH_MY_ORDERS_SUCCESS,
  ADD_MY_ORDER,
  DELETE_MY_ORDER,
  UPDATE_MY_ORDER,
  ORDER_ERROR,
} from "../const";

const initialState = {
  myOrders: [],
  error: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MY_ORDERS_SUCCESS:
      return {
        ...state,
        myOrders: action.payload,
      };
    case ADD_MY_ORDER:
      return {
        ...state,
        // myOrders: [...state.myOrders, action.payload],
        myOrders: action.payload,
      };
    case UPDATE_MY_ORDER:
      return {
        ...state,
        // myOrders: state.myOrders.map((order) =>
        //   order.id === action.payload.id ? action.payload : order
        // ),
        myOrders: action.payload,
      };
    case DELETE_MY_ORDER:
      return {
        ...state,
        // myOrders: state.myOrders.filter((order) => order.id !== action.payload),
        myOrders: action.payload,
      };
    case ORDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default orderReducer;
