// ForexSetupRow.js
import React from "react";
import { Button } from "primereact/button";
import { AiOutlineSwap } from "react-icons/ai";
import currencyData from "../../Currency/currency.json";

const ForexSetupRow = ({ myOrder, editMyOrder, confirmDel }) => {
  const currencyPair = (data) => {
    const fromCurrency = currencyData.find(
      (e) => e.code === data.primary_currency
    );
    const toCurrency = currencyData.find(
      (e) => e.code === data.secondary_currency
    );

    return (
      <div className="flex flex-row flagsExch">
        <img
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flagSetup flag ${
            fromCurrency ? `flag-${fromCurrency.countryCode.toLowerCase()}` : ""
          }`}
          alt={data.primary_currency}
        />
        <div className="item iconWrap">
          <AiOutlineSwap id="swapIcon" />
        </div>
        <img
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flagSetup flag ${
            toCurrency ? `flag-${toCurrency.countryCode.toLowerCase()}` : ""
          }`}
          alt={data.secondary_currency}
        />
      </div>
    );
  };

  const progressBar = (data) => {
    const traded = data.tradedAmount || 0;
    const total = data.amount || 0;
    return (
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${(traded / total) * 100}%` }}
        ></div>
        <span className="progress-bar-text">
          {traded.toFixed(2)} / {total.toFixed(2)} {data.to}
        </span>
      </div>
    );
  };

  const exchangeRate = (data) => {
    return (
      <span>
        {data.rate
          ? `${data.rate.toFixed(4)} ${data.secondary_currency} per ${
              data.primary_currency
            }`
          : "N/A"}
      </span>
    );
  };

  return (
    <div className="custom-table-row">
      <div>{currencyPair(myOrder)}</div>
      <div>{exchangeRate(myOrder)}</div>
      <div>{progressBar(myOrder)}</div>
      <div>
        <Button
          icon="pi pi-pencil"
          onClick={() => editMyOrder(myOrder)}
          text
        ></Button>
      </div>
      <div>
        <Button
          className="delBtn"
          icon="pi pi-times"
          severity="danger"
          onClick={() => confirmDel(myOrder)}
          text
        ></Button>
      </div>
    </div>
  );
};

export default ForexSetupRow;
