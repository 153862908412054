import axios from "axios";
import {
  FETCH_MY_ORDERS_SUCCESS,
  ADD_MY_ORDER,
  DELETE_MY_ORDER,
  UPDATE_MY_ORDER,
  ORDER_ERROR,
} from "../const";

const apiUrl = process.env.REACT_APP_API_URL;

// Action creators
export const fetchMyOrdersSuccess = (orders) => ({
  type: FETCH_MY_ORDERS_SUCCESS,
  payload: orders,
});

export const addMyOrder = (order) => ({
  type: ADD_MY_ORDER,
  payload: order,
});

export const updateMyOrder = (order) => ({
  type: UPDATE_MY_ORDER,
  payload: order,
});

export const deleteMyOrder = (order) => ({
  type: DELETE_MY_ORDER,
  payload: order,
});

export const orderError = (error) => ({
  type: ORDER_ERROR,
  payload: error,
});

export const fetchMyOrders = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/trade/getAllTradesByUserId?userId=${userId}`
    );
    console.log("order action fetchorders", response);
    let orders = response.data || [];
    if (orders.length) {
      orders = orders.filter((order) => order.status !== "CANCELED");
    }
    dispatch(fetchMyOrdersSuccess(orders));
  } catch (error) {
    dispatch(orderError("Failed to fetch orders."));
  }
};

export const postNewOrder = (orderData, userId, email) => async (dispatch) => {
  console.log("post new action: ", orderData);
  const mappedData = {
    amount: orderData.max,
    primary_currency: orderData.from,
    secondary_currency: orderData.to,
    rate: orderData.rate,
    user_id: userId,
    email: email,
    default_card: orderData.selectedCard.id,
    default_bank: orderData.selectedAccount.bankAccountId,
  };

  try {
    const response = await axios.post(`${apiUrl}/trade/create`, {
      ...mappedData,
    });
    if (response.data.success) {
      dispatch(addMyOrder(response.data.orders));
    }
  } catch (error) {
    dispatch(orderError("Failed to post new order."));
  }
};

export const updateOrder = (orderData) => async (dispatch) => {
  // console.log("updateorder in action: ", orderData);
  const mappedData = {
    id: orderData.id,
    amount: orderData.max,
    rate: orderData.rate,
  };
  try {
    const response = await axios.patch(`${apiUrl}/trade/update`, {
      ...mappedData,
    });
    if (response.data.success) {
      dispatch(updateMyOrder(response.data.orders));
    }
  } catch (error) {
    dispatch(orderError("Failed to update order."));
  }
};

export const deleteOrder = (orderId) => async (dispatch) => {
  try {
    // console.log("delete order in action: ", orderId);
    const response = await axios.delete(
      `${apiUrl}/trade/cancel?tradeId=${orderId}`
    );
    if (response.data.success) {
      dispatch(deleteMyOrder(response.data.orders));
    }
  } catch (error) {
    dispatch(orderError("Failed to delete order."));
  }
};
