import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import "./creditCardInfo.css";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { addCreditCardInfo } from "../../Redux/Action/userAction";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function CreditCardInfo() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const loginUser = useSelector((state) => state.user.user.data);

  const [formData, setFormData] = useState({
    cardholderName: "",
    email: "",
    areaCode: "",
    phone: "",
    billingAddress1: "",
    billingAddress2: "",
    province: "",
    postalCode: "",
    country: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (loginUser) {
      setFormData({
        ...formData,
        cardholderName: `${loginUser.first_name} ${loginUser.last_name}`,
        email: loginUser.email,
      });
    }
  }, [loginUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.cardholderName)
      tempErrors.cardholderName = "Cardholder's Name is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    console.log("credit card submit: ", formData);

    if (validate()) {
      dispatch(
        addCreditCardInfo(
          elements,
          formData,
          loginUser.email,
          loginUser.customer_enable,
          stripe
        )
      )
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "",
            detail: "Payment method added, banking info updated",
          });
        })
        .catch(() => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to add payment method",
          });
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Please fill out all fields correctly",
      });
    }
  };

  // console.log("creditcard page loginuser: ", loginUser);
  return (
    <>
      <Button className="backBtn" onClick={() => navigate("/")} text>
        <BiArrowBack />
      </Button>
      <br />

      <form onSubmit={handleSubmit} className="profileWrap">
        <div className="cardRow">
          <div className="cardCols creditCardCol">
            <h2>Credit Card Info</h2>
            <div className="inputRow">
              <div className="inputCol fullWidth">
                <label className="label" htmlFor="cardholderName">
                  Cardholder's Name
                </label>
                <InputText
                  id="cardholderName"
                  name="cardholderName"
                  value={formData.cardholderName}
                  className="p-inputtext-sm"
                  readOnly
                />
                {errors.cardholderName && (
                  <small className="p-error">{errors.cardholderName}</small>
                )}
              </div>
            </div>
            <div className="inputRow">
              <div className="inputCol fullWidth">
                <label className="label" htmlFor="email">
                  Email
                </label>
                <InputText
                  id="email"
                  name="email"
                  value={formData.email}
                  className="p-inputtext-sm"
                  readOnly
                />
                {errors.email && (
                  <small className="p-error">{errors.email}</small>
                )}
              </div>
            </div>
            <div className="inputRow">
              <div className="inputCol fullWidth">
                <label className="label" htmlFor="cardNumber">
                  Card Number
                </label>
                <CardNumberElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#32325d",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#fa755a",
                      },
                    },
                  }}
                  className="StripeElement"
                />
              </div>
            </div>
            <div className="inputRow flexContainer">
              <div className="inputCol halfWidth">
                <label className="label" htmlFor="cardExpiry">
                  Expiration Date
                </label>
                <CardExpiryElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#32325d",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#fa755a",
                      },
                    },
                  }}
                  className="StripeElement"
                />
              </div>
              <div className="inputCol halfWidth">
                <label className="label" htmlFor="cardCvc">
                  CVC
                </label>
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#32325d",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#fa755a",
                      },
                    },
                  }}
                  className="StripeElement"
                />
              </div>
            </div>
            <div className="buttons">
              <Button type="submit">Submit</Button>
              <Button onClick={() => navigate("/bankinginfo")}>
                Add direct deposit info
              </Button>
            </div>
          </div>
        </div>
      </form>
      <Toast ref={toast} />
    </>
  );
}
