import { configureStore } from "@reduxjs/toolkit";
import { combinedReducer } from "./Redux/Reducer/index";
import { loadState, saveState } from "./utils/localStorage";

const persistedState = loadState();

const store = configureStore({
  reducer: combinedReducer,
  preloadedState: persistedState, // Initialize with persisted state
});

// Subscribe to store updates
store.subscribe(() => {
  saveState({
    user: store.getState().user,
    orders: store.getState().orders,
  });
});

export default store;
