import React, { useState, useEffect, useRef } from "react";
import "./quoteDialog.css";
import { Dialog } from "primereact/dialog";
import QuoteDialogDetails from "./quoteDialogDetails";
export default function QuoteDialog(props) {
  const dialogHide = () => {
    props.setVisible(false);
  };
  useEffect(()=>{console.log(props)},[props])
  return (
    <Dialog
      className="quoteDialog"
      visible={props.visible}
      onHide={() => {
        dialogHide();
      }}
    >
      <QuoteDialogDetails  {...props}/>
    </Dialog>
  );
}
