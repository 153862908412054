export const UserService = {
  getData() {
    return [
      {
        id: 1000,
        name: "James Bill",
        uname: "buyertest",
        password: "buyertest",
        roles: ["buyer"],
        email: "xudawww757@gmail.com",
        ava: "https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png",
        firstName: "James",
        lastName: "White",
        area: 1,
        phone: 17788378422,
        nations: ["CAD", "USD", "CNY"],
        bankingInfoRegistered: false,
      },
      {
        id: 1001,
        name: "Thomas Shin",
        uname: "sellertest",
        password: "sellertest",
        roles: ["buyer", "seller"],
        email: "xudawww757@gmail.com",
        ava: "https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png",
        firstName: "Thomas",
        lastName: "Shin",
        area: 1,
        phone: 7788378422,
        nations: ["HKD"],
      },
      {
        id: 1002,
        name: "Onyama Limba",
        uname: "sellertest1",
        password: "sellertest1",
        roles: ["buyer", "seller"],
        email: "xudawww757@gmail.com",
        ava: "https://primefaces.org/cdn/primereact/images/avatar/onyamalimba.png",
        firstName: "Onyama",
        lastName: "Limba",
        area: 1,
        phone: 7788378422,
        nations: ["CAD", "CNY"],
      },
      {
        id: 1003,
        name: "Asiya Javayant",
        uname: "sellertest2",
        password: "sellertest2",
        roles: ["buyer", "seller"],
        email: "xudawww757@gmail.com",
        ava: "https://primefaces.org/cdn/primereact/images/avatar/asiyajavayant.png",
        firstName: "Onyama",
        lastName: "Limba",
        area: 1,
        phone: 7788378422,
        nations: ["CAD"],
      },
      {
        id: 1004,
        name: "Ioni Bowcher",
        uname: "sellertest3",
        password: "sellertest3",
        roles: ["buyer", "seller"],
        email: "xudawww757@gmail.com",
        ava: "https://primefaces.org/cdn/primereact/images/avatar/ionibowcher.png",
        firstName: "Ioni",
        lastName: "Bowcher",
        area: 1,
        phone: 7788378422,
        nations: ["CAD"],
      },
    ];
  },
};
