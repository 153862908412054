import "./history.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineSwap } from "react-icons/ai";
import currencyData from "../../Currency/currency.json";
import { useLocation } from "react-router-dom";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderHistory } from "../../Redux/Action/userAction";

export default function History() {
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.user.user.data);
  const orderHistory = useSelector((state) => state.user.orderHistory);
  const props = useLocation();
  const [notId, setNotId] = useState(null);
  let navigate = useNavigate();

  const backMain = () => {
    navigate("/");
  };

  useEffect(() => {
    if (props) {
      if (props.state?.id) {
        setNotId(props.state.id);
      }
      // console.log(props);
    }
  }, [props]);

  const rowClass = (data) => {
    return {
      "bg-primary": notId === data.id,
    };
  };

  useEffect(() => {
    if (loginUser) {
      dispatch(fetchOrderHistory(loginUser.id));
    }
    console.log("history page", loginUser);
  }, [loginUser, dispatch]);

  const getSeverity = (status) => {
    switch (status) {
      case "unqualified":
        return "danger";

      case "done":
        return "success";

      case "new":
        return "info";

      case "negotiation":
        return "warning";

      case "renewal":
        return null;

      default:
        return null;
    }
  };

  const currencyPair = (data) => {
    return (
      <div className="flex flex-row flagsExch">
        <img
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flagSetup flag flag-${currencyData
            .find((e) => e.code === data.primary_currency)
            .countryCode.toLowerCase()}`}
          alt={`${data.from} flag`}
        />
        <div className="item iconWrap">
          <AiOutlineSwap id="swapIcon" className="biIcon" />
        </div>
        <img
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flagSetup flag flag-${currencyData
            .find((e) => e.code === data.secondary_currency)
            .countryCode.toLowerCase()}`}
          alt={`${data.to} flag`}
        />
      </div>
    );
  };

  const orderDetails = (data) => {
    return (
      <div className="flex flex-row gap-2">
        {/* <p className="buyingLab">
          Purchased {data.sellterAmounts.toFixed(2)} {data.to} with{" "}
          {data.amount_paid.toFixed(2)} {data.from}
        </p> */}
        <p className="buyingLab">
          Paid {data.amount_paid.toFixed(2)} {data.primary_currency} for{" "}
          {data.purchase_amount.toFixed(2)} {data.secondary_currency}
        </p>
      </div>
    );
  };

  const orderType = (data) => {
    return (
      <div className="flex flex-row gap-2">
        <p className="buyingLab">Purchase</p>
      </div>
    );
  };

  const time = (data) => {
    return <p>UTC: {new Date(data.created_date).toLocaleString()}</p>;
  };

  const status = (data) => {
    return <Tag value={data.status} severity={getSeverity(data.status)} />;
  };

  return (
    <div className="orderPageWrap">
      <Button
        onClick={() => {
          backMain();
        }}
        text
      >
        <BiArrowBack />{" "}
      </Button>{" "}
      <DataTable
        rowClassName={rowClass}
        value={orderHistory}
        paginator
        rows={10}
        dataKey="id"
        emptyMessage="No orders found."
      >
        <Column
          header="Currency Exchange"
          style={{ minWidth: "14rem" }}
          body={currencyPair}
        />
        {/* <Column header="Type" body={orderType} style={{ minWidth: "10rem" }} /> */}
        <Column
          header="Order Details"
          body={orderDetails}
          style={{ minWidth: "10rem" }}
        />
        <Column header="Time" style={{ minWidth: "12rem" }} body={time} />
        {/* <Column header="Status" style={{ minWidth: "12rem" }} body={status} /> */}
      </DataTable>
    </div>
  );
}
