import { Routes, Route } from "react-router-dom";
import Home from "../Component/ProductPage/productPage";
import QuoteListPage from "../Component/QuoteListPage/quoteListPage";
import IdCheck from "../Component/IDCheck/idCheck";
import Profile from "../Component/Profile/profile";
import Forexsetup from "../Component/ForexSetup/forexsetup";
import Order from "../Component/Orders/orders";
import CreditCardInfo from "../Component/BankingInfo/creditCardInfo";
import DirectDepositeInfo from "../Component/BankingInfo/directDepositeInfo";
import BuyPage from "../Component/BuyPage/buyPage";
import History from "../Component/History/history";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/quote" element={<QuoteListPage />} />
      <Route path="/idcheck" element={<IdCheck />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/forexsetup" element={<Forexsetup />} />
      <Route path="/buypage" element={<BuyPage />} />
      <Route path="/history" element={<History />} />
      <Route path="/order" element={<Order />} />
      <Route path="/paymentinfo" element={<CreditCardInfo />} />
      <Route path="/bankinginfo" element={<DirectDepositeInfo />} />
    </Routes>
  );
}

export default Router;
