import "./quoteListPage.css";
import "./flag.css";
import React, { useState, useEffect } from "react";
import { memo } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { UserService } from "../../Service/UserService";
import { AiOutlineSwap } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import QuoteDialog from "../QuoteDialog/quoteDialog";
import { useLocation } from "react-router-dom";
import { getDatabase, ref, onValue } from "firebase/database";
import { loginUser } from "../../Redux/Action/userAction";
function QuoteListPage() {
  let navigate = useNavigate();
  const props = useLocation();
  const [customers, setCustomers] = useState([]);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [currentRate, setCurrentRate] = useState(null);
  const [quoteDialogVisible, setQuoteDialogVisible] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [orders, setOrders] = useState([]);
  const [loginUser, setLoginUser] = useState(null);
  const quotes = [321.12, 322.14, 232.11, 234.5];
  const [statuses] = useState([
    "unqualified",
    "qualified",
    "new",
    "negotiation",
    "renewal",
  ]);

  const getSeverity = (status) => {
    switch (status) {
      case "unqualified":
        return "danger";

      case "qualified":
        return "success";

      case "new":
        return "info";

      case "negotiation":
        return "warning";

      case "renewal":
        return null;
    }
  };
  useEffect(() => {
    console.log(props);
  });
  useEffect(() => {
    console.log(process.env.REACT_APP_USERKEY);
    console.log(
      JSON.parse(localStorage.getItem(process.env.REACT_APP_USERKEY))
    );
    if (localStorage.getItem(process.env.REACT_APP_USERKEY))
      setLoginUser(
        JSON.parse(localStorage.getItem(process.env.REACT_APP_USERKEY))
      );
  }, []);
  // useEffect(() => {
  //   const db = getDatabase();
  //   console.log(props.state);
  //   const starCountRef = ref(db, `setup/${props.state.to}-${props.state.from}`);
  //   onValue(starCountRef, (snapshot) => {
  //     if (snapshot.exists()) {
  //       let returnList = [];
  //       snapshot.forEach((childSnapshot) => {
  //         returnList.push({
  //           ...childSnapshot.val(),
  //           uid: childSnapshot.key.substring(2),
  //         });
  //       });
  //       console.log(returnList);
  //       setOrders(returnList);
  //     }
  //   });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const orderCur = (data) => {
    setQuoteDialogVisible(true);
    setCurrentAgent(UserService.getData().find((e) => e.id == data.uid));
    setCurrentRate(data.rate);
  };
  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
        {props.state && (
          <div className="flex justify-content-start">
            {props.state.rate !== 0 && (
              <p>
                {props.state.amount} {props.state.from} ={" "}
                {(props.state.amounts * props.state.rate).toFixed(2)}
                {props.state.to}{" "}
              </p>
            )}
          </div>
        )}
      </>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt="flag"
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flag flag-${props.state.fromCode.toLowerCase()}`}
          style={{ width: "24px" }}
        />
        <span>{props.state.from}</span>
        <AiOutlineSwap />

        <img
          alt="flag"
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flag flag-${props.state.toCode.toLowerCase()}`}
          style={{ width: "24px" }}
        />

        <span>
          {props.state.to}
          {}
        </span>
      </div>
    );
  };

  const representativeBodyTemplate = (data) => {
    return (
      <div className="flex align-items-center ">
        <img
          src={UserService.getData().find((e) => e.id == data.uid).ava}
          width="32"
        />

        <span style={{ marginTop: "8px", marginLeft: "10px" }}>
          {UserService.getData().find((e) => e.id == data.uid).name}
        </span>
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={"Open"} severity={getSeverity("qualified")} />;
  };

  const verifiedBodyTemplate = (data) => {
    return (
      <Button
        outlined
        disabled={data.uid == loginUser.id}
        onClick={() => {
          orderCur(data);
        }}
      >
        Order
      </Button>
    );
  };
  const representativeRateTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>
          {" "}
          1 {props.state.from} = {(1 / data.rate).toFixed(2)} {props.state.to}{" "}
        </span>
      </div>
    );
  };
  const representativeQuoteTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>${(props.state.amounts * data.rate).toFixed(2)} CAD</span>
      </div>
    );
  };

  const backMain = () => {
    navigate("/");
  };

  const header = renderHeader();

  return (
    <div className="card quotePageWrap">
      <Button
        onClick={() => {
          backMain();
        }}
        text
      >
        <BiArrowBack />{" "}
      </Button>

      <DataTable
        value={orders}
        paginator
        rows={10}
        dataKey="id"
        emptyMessage="No customers found."
      >
        <Column
          header="Agent"
          filterField="representative"
          showFilterMenu={false}
          filterMenuStyle={{ width: "14rem" }}
          style={{ minWidth: "14rem" }}
          body={representativeBodyTemplate}
        />
        <Column
          field="quote"
          header="Quote"
          body={representativeQuoteTemplate}
          filterPlaceholder="Search by Quote"
          style={{ minWidth: "12rem" }}
        />
        <Column
          field="rate"
          header="Rate"
          body={representativeRateTemplate}
          filterPlaceholder="Search by Quote"
          style={{ minWidth: "12rem" }}
        />
        <Column
          header="Currency"
          filterField="country.name"
          style={{ minWidth: "12rem" }}
          body={countryBodyTemplate}
        />
        <Column
          field="status"
          header="Status"
          showFilterMenu={false}
          filterMenuStyle={{ width: "14rem" }}
          style={{ minWidth: "12rem" }}
          body={statusBodyTemplate}
        />
        <Column style={{ minWidth: "6rem" }} body={verifiedBodyTemplate} />
      </DataTable>
      {}
      {currentAgent && props && (
        <QuoteDialog
          visible={quoteDialogVisible}
          setVisible={setQuoteDialogVisible}
          from={props.state.from}
          to={props.state.to}
          fromCode={props.state.fromCode}
          toCode={props.state.toCode}
          amounts={props.state.amounts}
          user={loginUser}
          agent={currentAgent}
          rate={currentRate}
        />
      )}
    </div>
  );
}
export default memo(QuoteListPage);
