import React, { useState, useEffect, useRef } from "react";
import { Menubar } from "primereact/menubar";
import "./mainMenu.css";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useSelector, useDispatch } from "react-redux";
import LoginRegDialog from "../../Component/LoginRegsiterDialog/loginRegDialog";
import { logoutUser } from "../../Redux/Action/userAction";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { getDatabase, ref, set, get, child, onValue } from "firebase/database";
export default function MainMenu() {
  const profileMenu = useRef(null);
  const noteMenu = useRef(null);
  const [loginUser, setLoginUser] = useState(null);
  const [notiList, setNotiList] = useState([]);
  const [unreadNum, setUnreadNum] = useState(0);
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const db = getDatabase();
  useEffect(() => {
    // console.log(user.user);
    if (!user.error && user.user.login) {
      setLoginRegsiterVisible(false);
      if (localStorage.getItem(process.env.REACT_APP_USERKEY))
        setLoginUser(
          JSON.parse(localStorage.getItem(process.env.REACT_APP_USERKEY))
        );
    }
    if (!user.user.login) {
      setLoginUser(null);
    }
  }, [user]);
  useEffect(() => {
    // console.log(process.env.REACT_APP_USERKEY);
    if (localStorage.getItem(process.env.REACT_APP_USERKEY))
      setLoginUser(
        JSON.parse(localStorage.getItem(process.env.REACT_APP_USERKEY))
      );
  }, []);

  useEffect(() => {
    if (loginUser) {
      const starCountRef = ref(db, "notification/no" + loginUser.id);
      onValue(starCountRef, (snapshot) => {
        if (snapshot.exists()) {
          let returnList = [];
          snapshot.forEach((childSnapshot) => {
            returnList.push(childSnapshot.val());
          });
          setNotiList(
            returnList.map((e) => {
              // console.log(e);
              return {
                label: e.note,
                unread: e.unread,
                command: () => {
                  set(
                    ref(db, `notification/no${loginUser.id}/${e.id}/unread`),
                    false
                  );
                  navigate("/order", {
                    state: {
                      id: e.id,
                    },
                  });
                },
              };
            })
          );
          console.log("menu notilist: ", notiList);
        }
      });
    }
  }, [loginUser]);

  useEffect(() => {
    setUnreadNum(notiList.filter((e) => e.unread).length);
    // console.log(notiList.filter((e) => e.unread !== false).length);
  }, [notiList]);
  const itemsProfileMenu = [
    {
      label: "Profile",
      command: () => {
        navigate("/profile");
      },
    },
    {
      label: "Hisotry",
      command: () => {
        navigate("/history");
      },
    },
    {
      label: "Logout",
      command: () => {
        dispatch(logoutUser());
        navigate("/");
      },
    },
  ];
  const [menuList, setMenuList] = useState([
    {
      label: "Trade",
      active: true,
      canBeAct: true,
      route: "/",
      hideLogin: false,
    },
    {
      label: "Login/Register",
      active: false,
      canBeAct: false,
      route: null,
      onlyshowLogin: false,
    },
  ]);
  const [loginRegsiterVisible, setLoginRegsiterVisible] = useState(false);
  const start = (
    <div className="flex">
      <h1>Miracle Pace</h1>
    </div>
  );
  const clickActive = (i) => {
    let menuListClone = [...menuList];
    if (menuListClone[i].canBeAct) {
      menuListClone.forEach((e, ii) => {
        menuListClone[ii].active = false;
      });

      menuListClone[i].active = true;
      console.log(menuListClone[i].label);
      setMenuList(menuListClone);
    }
    if (menuListClone[i].label === "Login/Register") {
      setLoginRegsiterVisible(true);
    }
  };

  return (
    <>
      {" "}
      <Menubar
        className="mainMenu"
        start={start}
        end={
          <div className="flex align-items-center gap-2 endMenuBtns">
            {loginUser && (
              <div className="flex align-items-center gap-2">
                {/* <InputText
                  placeholder="Search"
                  type="text"
                  className="w-8rem sm:w-auto"
                /> */}
                <i
                  className="pi pi-user"
                  style={{ fontSize: "1.5rem" }}
                  onClick={(event) => profileMenu.current.toggle(event)}
                ></i>

                <p>{user.user.data && user.user.data.username}</p>
                <Button
                  icon="pi pi-bell"
                  rounded
                  text
                  aria-label="Cancel"
                  onClick={(event) => noteMenu.current.toggle(event)}
                  badge={unreadNum}
                  badgeClassName="p-badge-danger"
                />
                <Menu
                  model={itemsProfileMenu}
                  popup
                  ref={profileMenu}
                  id="popup_menu_left"
                  aria-controls="popup_menu_ava"
                  aria-haspopup
                />

                <Menu
                  model={notiList}
                  popup
                  ref={noteMenu}
                  id="popup_menu_left"
                  aria-controls="popup_menu_ava"
                  aria-haspopup
                />
              </div>
            )}
            {!loginUser && (
              <ul className="endItemUl">
                {menuList.map((e, i) => {
                  return (
                    <li
                      key={e.label}
                      onClick={() => clickActive(i)}
                      className={e.active && e.canBeAct ? "activeItem" : ""}
                    >
                      <Link to={e.route}>{e.label}</Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        }
      />
      <LoginRegDialog
        visible={loginRegsiterVisible}
        setVisible={setLoginRegsiterVisible}
      />
    </>
  );
}
