// TradingViewWidget.jsx
import React, { useEffect, useRef, useState, memo } from "react";
import "./forexChart.css";
import { TabMenu } from "primereact/tabmenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
import moment from "moment";
function ForexChart({ pair }) {
  const [activeIndex, setActiveIndex] = useState(2);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const chartLinelItem = [
    { label: "Y", val: "year", format: "YYYY" },
    { label: "M", val: "month", format: "YYYY-MM" },
    { label: "D", val: "day", format: "YYYY-MM-DD" },
    {
      label: "H",

      val: "hour",
      format: "YYYY-MM-DD HH:MM",
    },
  ];
  const container = useRef();
  const currentDate = new Date();
  // Format current date as "YYYY-MM-DD"
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  //5 days ago
  const fiveDaysAgo = new Date(currentDate);
  fiveDaysAgo.setDate(currentDate.getDate() - 5);

  const oneDayAgo = new Date(currentDate);
  oneDayAgo.setDate(oneDayAgo.getDate() - 1);
  const rangeTimeFrame = [
    {
      start: currentYear - 10 + "-" + currentMonth + "-" + currentDay,
      end: currentYear + "-" + currentMonth + "-" + currentDay,
    },
    {
      start: currentYear - 1 + "-" + currentMonth + "-" + currentDay,
      end: currentYear + "-" + currentMonth + "-" + currentDay,
    },
    {
      start:
        fiveDaysAgo.getFullYear() +
        "-" +
        String(fiveDaysAgo.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(fiveDaysAgo.getDate()).padStart(2, "0"),
      end: currentYear + "-" + currentMonth + "-" + currentDay,
    },
    {
      start:
        oneDayAgo.getFullYear() +
        "-" +
        String(oneDayAgo.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(oneDayAgo.getDate()).padStart(2, "0"),
      end: currentYear + "-" + currentMonth + "-" + currentDay,
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Rate Comparison",
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };
  const fetchData = async () => {
    try {
      const responseIp = await fetch("https://api.ipify.org/?format=json");
      if (!responseIp.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonDataIP = await responseIp.json();
      // console.log(jsonDataIP);
      const response = await fetch(
        `${process.env.REACT_APP_CHARTURL}C:${pair}/range/1/${chartLinelItem[activeIndex].val}/${rangeTimeFrame[activeIndex].start}/${rangeTimeFrame[activeIndex].end}?apiKey=${process.env.REACT_APP_APIKEY}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      if (jsonData.results) {
        setChartLabels(
          jsonData.results.map((e) =>
            moment(e.t).format(chartLinelItem[activeIndex].format)
          )
        );
        setChartData1(jsonData.results.map((e) => e.c));
        setChartData2(jsonData.results.map((e) => e.c + 0.031));
      } else {
        setChartData1(null);
        setChartData2(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };
  useEffect(() => {
    fetchData();
  }, [activeIndex, pair]);

  return (
    <div style={{ height: "400px" }}>
      <TabMenu
        model={chartLinelItem}
        className="timeTab"
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      />
      {chartData1 && chartData2 ? (
        <Line
          options={options}
          data={{
            labels: chartLabels,
            datasets: [
              {
                label: "Polygon rate",
                data: chartData1,
                borderColor: "rgb(235, 53, 95)",
                backgroundColor: "white",
              },
              {
                label: "Miracle rate",
                data: chartData2,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "white",
              },
            ],
          }}
          ref={container}
        />
      ) : (
        <>No data</>
      )}
    </div>
  );
}

export default memo(ForexChart);
