import "./styles.css";
import "./firebaseConfig"
import ProductPage from "./Component/ProductPage/productPage";
import MainMenu from "./Menu/MainMenu/mainMenu";
import Router from "../src/Router/router";
export default function App() {
  return (
    <div className="App">
      <div className="card">
        <MainMenu />
        <Router />
      </div>
    </div>
  );
}
