import { createRoot } from "react-dom/client";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import App from "./App";
import { PrimeReactProvider } from "primereact/api";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { initializeUserFromStorage } from "./Redux/Action/userAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Initialize Stripe with your public key
const stripePromise = loadStripe(
  "pk_test_51PZXFhRvavSHZVmWwiC7JFMPHth5NcY6xhcuFwQcKGBflKRvUfLn0YzcZub8RVuGhwMCMWGIHe97Qp4rtXYZfxUf00FqCFz2W6"
);

// Initialize user data from local storage
store.dispatch(initializeUserFromStorage());

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <PrimeReactProvider>
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </BrowserRouter>
    </PrimeReactProvider>
  </Provider>
);
