import {
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_ERROR,
  PURCHASE_SUCCESS,
  PURCHASE_ERROR,
} from "../const";

const initialState = {
  posts: [],
  error: null,
  purchase: null, // Added purchase state
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.payload,
      };
    case FETCH_POSTS_ERROR:
    case PURCHASE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case PURCHASE_SUCCESS:
      return {
        ...state,
        purchase: action.payload, // Store purchase success data
      };
    default:
      return state;
  }
};

export default postReducer;
