import { CardNumberElement } from "@stripe/react-stripe-js";
import {
  LOGIN,
  LOGOUT,
  USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_ORDER_HISTORY_ERROR,
} from "../const";
import axios from "axios";
import currencies from "../../Currency/currency.json";

const apiUrl = process.env.REACT_APP_API_URL;

export const login = (user) => ({
  type: LOGIN,
  payload: user,
});

export const logout = () => ({
  type: LOGOUT,
});

export const addErr = (err) => ({
  type: USER_ERROR,
  payload: err,
});

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});

export const initializeUserFromStorage = () => (dispatch) => {
  const storedUser = localStorage.getItem(process.env.REACT_APP_USERKEY);
  if (storedUser !== "undefined") {
    dispatch(login(JSON.parse(storedUser)));
  }
};

const fetchUpdatedBankingInfo = async (dispatch, email) => {
  const getCardUrl = `${apiUrl}/payment/getCards`;
  const getAccountsUrl = `${apiUrl}/payment/bankInfo`;

  try {
    const [cardResponse, accountResponse] = await Promise.all([
      axios.get(`${getCardUrl}?email=${email}`),
      axios.get(`${getAccountsUrl}?email=${email}`),
    ]);

    // console.log("fetchbanking res: ", cardResponse, accountResponse);

    let from = ["USD", "CNY"],
      to = ["CNY", "JPY"];

    if (cardResponse.data && cardResponse.data.length) {
      for (let card of cardResponse.data) {
        const currencyInfo = currencies.find(
          (currency) => currency.countryCode === card.country
        );
        if (currencyInfo && !from.includes(currencyInfo.code)) {
          from.push(currencyInfo.code);
        }
      }
    }
    if (
      accountResponse.data.bankAccounts &&
      accountResponse.data.bankAccounts.length
    ) {
      for (let account of accountResponse.data.bankAccounts) {
        if (!to.includes(account.currency)) {
          to.push(account.currency.toUpperCase());
        }
      }
    }

    // console.log(from, to);
    if (cardResponse.status === 200 && accountResponse.status === 200) {
      const updatedUserInfo = {
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_USERKEY)),
        currency: {
          from,
          to,
        },
        bankingInfo: {
          paymentCards: cardResponse.data || [],
          depositAccounts: accountResponse.data.bankAccounts || [],
        },
      };

      localStorage.setItem(
        process.env.REACT_APP_USERKEY,
        JSON.stringify(updatedUserInfo)
      );
      dispatch(fetchUserSuccess(updatedUserInfo));
    } else {
      dispatch(addErr("Failed to fetch updated banking info"));
    }
  } catch (error) {
    console.log(error);
    dispatch(addErr("Failed to fetch updated banking info"));
  }
};

export const loginUser = ({ email, password }) => {
  const loginUrl = `${apiUrl}/user/login`;
  return async (dispatch) => {
    try {
      const loginResponse = await axios.post(loginUrl, {
        email: email,
        password,
      });

      console.log("login response: ", loginResponse);

      if (loginResponse.status === 200) {
        localStorage.setItem(
          process.env.REACT_APP_USERKEY,
          JSON.stringify(loginResponse.data)
        );
        dispatch(login(loginResponse.data));

        await fetchUpdatedBankingInfo(dispatch, email);
      } else {
        dispatch(addErr("Email and password do not match!"));
      }
    } catch (error) {
      console.log("Error during login: ", error);
      dispatch(addErr("Login failed, please try again!"));
    }
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(logout());
    localStorage.removeItem(process.env.REACT_APP_USERKEY);
  };
};

export const updateProfile = (userId, formData) => async (dispatch) => {
  console.log("action update profile: ", formData);
  try {
    const response = await axios.patch(`${apiUrl}/user`, {
      id: userId,
      ...formData,
      address:
        formData.address1 +
        "," +
        formData.address2 +
        "," +
        formData.province +
        "," +
        formData.postal_code +
        "," +
        formData.country,
    });

    if (response.status === 200) {
      localStorage.setItem(
        process.env.REACT_APP_USERKEY,
        JSON.stringify(response.data)
      );
      dispatch(fetchUserSuccess(response.data));
      await fetchUpdatedBankingInfo(dispatch, formData.email);
    } else {
      dispatch(addErr(response.data.message));
    }
  } catch (error) {
    dispatch(addErr("Failed to update profile."));
  }
};

export const fetchOrderHistorySuccess = (orders) => ({
  type: FETCH_ORDER_HISTORY_SUCCESS,
  payload: orders,
});

export const fetchOrderHistoryError = (error) => ({
  type: FETCH_ORDER_HISTORY_ERROR,
  payload: error,
});

export const fetchOrderHistory = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/transaction/getAllTransactionsByUserId?userId=${userId}`
    );
    console.log("history", response);
    const orders = response.data || [];
    dispatch(fetchOrderHistorySuccess(orders));
  } catch (error) {
    console.error("Failed to fetch order history:", error);
    dispatch(fetchOrderHistoryError("Failed to fetch order history."));
  }
};

export const deleteCardOrAccount = (email, id, type) => async (dispatch) => {
  try {
    let response;

    if (type === "Card") {
      response = await axios.get(
        `${apiUrl}/payment/deleteCard?email=${email}&cardId=${id}`
      );
    } else if (type === "Account") {
      response = await axios.get(
        `${apiUrl}/payment/deleteBankAccount?email=${email}&bankAccountId=${id}`
      );
    }

    if (response && response.status === 200) {
      await fetchUpdatedBankingInfo(dispatch, email);
      dispatch({
        type:
          type === "Card" ? "DELETE_CARD_SUCCESS" : "DELETE_ACCOUNT_SUCCESS",
        payload: id,
      });
    } else {
      dispatch({
        type: "USER_ERROR",
        payload: `Failed to delete ${type.toLowerCase()}`,
      });
    }
  } catch (error) {
    console.log("!!!!!!!!!!!!!!!!!error", error);
    dispatch({
      type: "USER_ERROR",
      payload:
        error.response?.data?.message ||
        `Failed to delete ${type.toLowerCase()}`,
    });
  }
};

export const createCardCustom = (formData) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${apiUrl}/payment/createCustomer?email=${formData.email}&token=${formData.token}`
    );

    if (response.status === 200) {
      console.log(response);
      await fetchUpdatedBankingInfo(dispatch, formData.email);
    } else {
      dispatch(addErr("Failed to create customer"));
    }
  } catch (error) {
    dispatch(addErr("Failed to create customer"));
  }
};

export const addCreditCardInfo =
  (elements, formData, email, customer_enable, stripe) => async (dispatch) => {
    try {
      const cardElement = elements.getElement(CardNumberElement);
      const { token, error } = await stripe.createToken(cardElement, {
        name: formData.cardholderName,
      });

      if (error) {
        console.error("Error creating credit card token:", error);
        dispatch(addErr("Failed to create card token"));
        return;
      }

      let response;
      if (!customer_enable) {
        response = await dispatch(
          createCardCustom({ ...formData, token: token.id }, email)
        );
      } else {
        response = await axios.get(
          `${apiUrl}/payment/addCard?email=${email}&token=${token.id}`
        );
      }

      if (response.status === 200) {
        await fetchUpdatedBankingInfo(dispatch, email);
      } else {
        dispatch(addErr("Failed to add credit card info"));
      }
    } catch (error) {
      dispatch(addErr("Failed to add credit card info"));
    }
  };

export const createCustomAccount =
  (formData, loginUser) => async (dispatch) => {
    console.log(
      "createCustomAccount formdata: ",
      formData,
      "loginUser: ",
      loginUser,
      loginUser.address.split(",").at(-1)
    );
    try {
      const response = await axios.get(
        `${apiUrl}/payment/customAccount?email=${formData.email}&firstName=${
          loginUser.first_name
        }&address=${loginUser.address}&country=${loginUser.address
          .split(",")
          .at(-1)}`
      );
      // 122 king st,toronto,ontario,M2K1E1&country=CA
      if (response.status === 200) {
        return response.data;
      } else {
        dispatch(addErr("Failed to create bank account"));
      }
    } catch (error) {
      dispatch(addErr("Failed to create bank account"));
    }
  };

export const addDirectDepositInfo =
  (formData, account_enable, stripe, loginUser) => async (dispatch) => {
    try {
      const { token, error } = await stripe.createToken("bank_account", {
        currency: formData.currency.code,
        country: formData.country.code,
        routing_number: formData.branchNumber + formData.transitNumber,
        account_number: formData.accountNumber,
        account_holder_name: formData.accountName,
        account_holder_type: "individual",
      });

      if (error) {
        // console.error("Error creating bank account token:", error);
        dispatch(addErr("Failed to create bank account token"));
        return Promise.reject(error);
      }

      let response;
      if (!account_enable) {
        response = await dispatch(
          createCustomAccount({ ...formData, token: token.id }, loginUser)
        );
      }

      response = await axios.get(
        `${apiUrl}/payment/bankAccount?email=${formData.email}&token=${token.id}`
      );
      // console.log("add acount res: ", response);

      if (response.status === 200) {
        await fetchUpdatedBankingInfo(dispatch, formData.email);
        return Promise.resolve();
      } else {
        dispatch(addErr("Failed to add bank account"));
        return Promise.reject(new Error("Failed to add bank account"));
      }
    } catch (error) {
      // console.error("Failed to add bank account:", error);
      dispatch(addErr("Failed to add bank account"));
      return Promise.reject(error);
    }
  };
