import { combineReducers } from "redux";
import userReducer from "./userReducer";
import orderReducer from "./orderReducer";
import postReducer from "./postReducer";

export const combinedReducer = combineReducers({
  user: userReducer,
  orders: orderReducer,
  posts: postReducer,
});
