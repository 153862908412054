import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import "./idCheck.css";
import frontIdImg from "../../public/img/Screenshot 2024-05-17 130852.png";
import backIdImg from "../../public/img/Screenshot 2024-05-17 133626.png";
import billImg from "../../public/img/Design-Invoice.jpg";
import businessImg from "../../public/img/Screenshot 2024-05-23 233801.png";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
export default function IDCheck({ mode }) {
  let navigate = useNavigate();
  const [ids, setIds] = useState([]);
  const [bill, setBill] = useState([]);
  const [sellerAppr, setSellerAppr] = useState([]);
  const hiddenFileInputId = useRef(null);
  const hiddenFileInputBill = useRef(null);
  const hiddenFileInputSeller = useRef(null);
  const toast = useRef(null);
  const handleClickId = (event) => {
    hiddenFileInputId.current.click();
  };
  const handleClickBill = (event) => {
    hiddenFileInputBill.current.click();
  };
  const handleClickSeller = (event) => {
    hiddenFileInputSeller.current.click();
  };
  const handleChangeId = (event) => {
    const fileUploaded = event.target.files[0];
    if (ids.length < 2) {
      setIds([...ids, URL.createObjectURL(fileUploaded)]);
    } else {
      let tempIds = [...ids];
      tempIds[1] = URL.createObjectURL(fileUploaded);
      setIds(tempIds);
    }
  };
  const handleChangeBill = (event) => {
    const fileUploaded = event.target.files[0];
    if (bill.length < 1) {
      setBill([...bill, URL.createObjectURL(fileUploaded)]);
    } else {
      let tempIds = [...bill];
      tempIds[0] = URL.createObjectURL(fileUploaded);
      setBill(tempIds);
    }
  };
  const handleChangeSeller = (event) => {
    const fileUploaded = event.target.files[0];
    if (sellerAppr.length < 1) {
      setSellerAppr([...sellerAppr, URL.createObjectURL(fileUploaded)]);
    } else {
      let tempIds = [...sellerAppr];
      tempIds[0] = URL.createObjectURL(fileUploaded);
      setSellerAppr(tempIds);
    }
  };
  const backMain = () => {
    navigate("/");
  };
  const cancelIdEvent = (i) => {
    setIds([...ids.slice(0, i), ...ids.slice(i + 1)]);
  };
  const cancelBillEvent = (i) => {
    setBill([...bill.slice(0, i), ...bill.slice(i + 1)]);
  };
  const cancelSellerEvent = (i) => {
    setSellerAppr([...sellerAppr.slice(0, i), ...sellerAppr.slice(i + 1)]);
  };
  const submit = () => {
    toast.current.show({
      severity: "success",
      summary: "",
      detail: "Successfully upload files, we will check and inform you soon.",
    });
  };
  useEffect(()=>{
   console.log(sellerAppr)

  },[sellerAppr])
  return (
    <div className="flex flex-column">
    <div className="pageWrap flex flex-row gap2">
      <div className="">
        <Button
          onClick={() => {
            backMain();
          }}
          text
        >
          <BiArrowBack />{" "}
        </Button>
        <br />

        <div className="idwrap">
          <h3>Buyer id checks:</h3>
          <br />

          <span>Please upload your id with 2 sides</span>
          <br />
          {ids.length === 0 && (
            <div className="imgWrap">
              <div>
                <img src={frontIdImg}></img>
              </div>
              <div>
                <img src={backIdImg}></img>
              </div>
            </div>
          )}
          {ids.length === 1 && (
            <div className="imgWrap">
              <div>
                <img src={ids[0]}></img>
                <Button
                  onClick={() => {
                    cancelIdEvent(0);
                  }}
                  icon="pi pi-times"
                  rounded
                  text
                  severity="danger"
                  aria-label="Cancel"
                />
              </div>
              <div>
                <img src={backIdImg}></img>
              </div>
            </div>
          )}
          {ids.length === 2 && (
            <div className="imgWrap">
              <div>
                <img src={ids[0]}></img>
                <Button
                  onClick={() => {
                    cancelIdEvent(0);
                  }}
                  icon="pi pi-times"
                  rounded
                  text
                  severity="danger"
                  aria-label="Cancel"
                />
              </div>
              <div>
                <img src={ids[1]}></img>
                <Button
                  onClick={() => {
                    cancelIdEvent(1);
                  }}
                  icon="pi pi-times"
                  rounded
                  text
                  severity="danger"
                  aria-label="Cancel"
                />
              </div>
            </div>
          )}
          <br />
          {ids.length < 2 && (
            <Button className="iduploadBtn" onClick={() => handleClickId()}>
              Upload ID
            </Button>
          )}
          <input
            type="file"
            onChange={handleChangeId}
            ref={hiddenFileInputId}
            style={{ display: "none" }} // Make the file input element invisible
          />
          <br />
        </div>
        <Toast ref={toast} />
        <div className="billWrap">
          {" "}
          <br />
          <span>
            Please upload your bill or bank statement to prove your address.{" "}
          </span>
          <br />
          {bill.length === 0 && (
            <div className="imgWrap">
              <div>
                <img className="billImg" src={billImg}></img>
              </div>
            </div>
          )}
          {bill.length === 1 && (
            <div className="imgWrap">
              <div>
                <img className="billImg" src={bill[0]}></img>
                <Button
                  onClick={() => {
                    cancelBillEvent(0);
                  }}
                  icon="pi pi-times"
                  rounded
                  text
                  severity="danger"
                  aria-label="Cancel"
                />
              </div>
            </div>
          )}
          <br />
          {bill.length < 1 && (
            <Button className="iduploadBtn" onClick={() => handleClickBill(0)}>
              Upload
            </Button>
          )}
          <input
            type="file"
            onChange={handleChangeBill}
            ref={hiddenFileInputBill}
            style={{ display: "none" }} // Make the file input element invisible
          />
          <br />
        </div>
      </div>
      <Divider layout="vertical" className="diverVertical" />
      <div className="sellerWrap">
        <h3>Seller's docs check(Optional):</h3>
        <br />
        <span>Please upload your business License. </span>
        <br />
        <br />
        <br />
        {sellerAppr.length === 0 && (
          <div className="imgWrap">
            <div>
              <img className="billImg" src={businessImg }></img>
            </div>
          </div>
        )}
        {sellerAppr.length === 1 && (
          <div className="imgWrap">
            <div>
              <img className="billImg" src={sellerAppr[0]}></img>
              <Button
                onClick={() => {
                  cancelSellerEvent(0);
                }}
                icon="pi pi-times"
                rounded
                text
                severity="danger"
                aria-label="Cancel"
              />
            </div>
          </div>
        )}
        <br />
        {sellerAppr.length < 1 && (
          <Button className="iduploadBtn" onClick={() => handleClickSeller()}>
            Upload
          </Button>
        )}
        <input
          type="file"
          onChange={handleChangeSeller}
          ref={hiddenFileInputSeller}
          style={{ display: "none" }} // Make the file input element invisible
        />
        <br />
      </div>
    </div>
    <div>
    <Button
      onClick={() => {
        submit();
      }}
      disabled={ids.length < 2 || bill.length < 1}
      className="submitBtn"
    >
      Submit
    </Button>
  </div>
  </div>
  );
}
