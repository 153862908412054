import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import LoginRegister from "./loginReg";
import { useDispatch } from "react-redux";
import { addErr } from "../../Redux/Action/userAction";
import { Button } from "primereact/button";
export default function LoginRegDialog({ visible, setVisible }) {
  const dispatch = useDispatch();
  const [mode, setMode] = useState("login");
  const dialogHide = () => {
    setVisible(false);
    setMode("login");
    dispatch(addErr(null));
  };
  return (
    <div className="card flex justify-content-center logRegWrap">
      <Dialog
        visible={visible}
        className={mode === "register" ? "regDialog" : "loginDialog"}
        onHide={() => {
          dialogHide();
        }}
      >
        <h2>Miracle</h2>
        <h2>Pace</h2>
        <LoginRegister mode={mode} setMode={setMode} />
        <div className="swichBtnWrap">
          {mode === "login" ? (
            <>
              <Button
                label="Forget password?"
                onClick={() => {
                  setMode("fpassword");
                }}
                link
              />
              <div className="flex">
                <p>Dont have an account?</p>
                <Button
                  style={{ paddingTop: 0, paddingLeft: 5 }}
                  label="Register"
                  onClick={() => {
                    setMode("register");
                  }}
                  link
                />
              </div>
            </>
          ) : (
            <div>
              <Button
                label="Click here to login"
                onClick={() => {
                  setMode("login");
                }}
                link
              />
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}
