import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import "./directDepositeInfo.css";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { addDirectDepositInfo } from "../../Redux/Action/userAction";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Dropdown } from "primereact/dropdown";
import currencyData from "../../Currency/currency.json";
import countryData from "../../Country/country.json";

export default function DirectDepositeInfo() {
  const [formData, setFormData] = useState({
    accountName: "",
    bankName: "",
    transitNumber: "",
    branchNumber: "",
    accountNumber: "",
    email: "",
    currency: null,
    country: null,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const loginUser = useSelector((state) => state.user.user.data);

  useEffect(() => {
    if (!stripe || !elements) {
      console.error("Stripe or Elements has not been loaded correctly.");
    }
  }, [stripe, elements]);

  useEffect(() => {
    if (loginUser) {
      setFormData((prevData) => ({
        ...prevData,
        email: loginUser.email,
      }));
    }
  }, [loginUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNumericChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCurrencyChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      currency: e.value,
    }));
  };

  const handleCountryChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      country: e.value,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.accountName)
      tempErrors.accountName = "Account Name is required";
    if (!formData.bankName) tempErrors.bankName = "Bank Name is required";
    if (!formData.transitNumber || !/^\d{5}$/.test(formData.transitNumber))
      tempErrors.transitNumber = "Valid Transit Number is required (5 digits)";
    if (!formData.branchNumber || !/^\d{3,6}$/.test(formData.branchNumber))
      tempErrors.branchNumber = "Valid Branch Number is required (5 digits)";
    if (!formData.accountNumber || !/^\d{7,12}$/.test(formData.accountNumber))
      tempErrors.accountNumber =
        "Valid Account Number is required (7-12 digits)";
    if (!formData.currency) tempErrors.currency = "Currency is required";
    if (!formData.country) tempErrors.country = "Country is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe) {
      toast.current.show({
        severity: "error",
        summary: "Stripe Error",
        detail: "Stripe is not loaded. Please try again later.",
      });
      return;
    }

    if (validate()) {
      dispatch(
        addDirectDepositInfo(
          formData,
          loginUser.account_enable,
          stripe,
          loginUser
        )
      )
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Banking Info Added, you can start trading now!",
          });
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message || "Failed to add banking info",
          });
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Please fill out all fields correctly",
      });
    }
  };

  const countryOptionTemplate = (option) => (
    <div className="flex align-items-center">
      <img className="flagImg" src={option.flag} alt={option.code} />
      <div>{option.name}</div>
    </div>
  );

  const selectedCountryTemplate = (option, props) =>
    option ? (
      <div className="flex align-items-center">
        <img alt={option.code} src={option.flag} className="flagImg" />
        <div>{option.name}</div>
      </div>
    ) : (
      <span>{props.placeholder}</span>
    );

  const currencyOptionTemplate = (option) => (
    <div className="flex align-items-center">
      <div>{option.code}</div>
    </div>
  );

  const selectedCurrencyTemplate = (option, props) =>
    option ? (
      <div className="flex align-items-center">
        <div>{option.code}</div>
      </div>
    ) : (
      <span>{props.placeholder}</span>
    );

  return (
    <>
      <Button className="backBtn" onClick={() => navigate("/")} text>
        <BiArrowBack />
      </Button>
      <br />

      <form onSubmit={handleSubmit} className="flex flex-row gap-2 profileWrap">
        <div className="flex flex-row cardRows">
          <div className="cardCols">
            <h1>Direct Deposite Info</h1>
            <div className="flex flex-row gap-4 mb-2">
              <div className="flex flex-column gap-2 bankName">
                <label className="label" htmlFor="accountName">
                  Name on Account
                </label>
                <InputText
                  id="accountName"
                  name="accountName"
                  value={formData.accountName}
                  onChange={handleChange}
                  className="p-inputtext-sm"
                />
                {errors.accountName && (
                  <small className="p-error">{errors.accountName}</small>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-4 mb-2">
              <div className="flex flex-column gap-2 bankName">
                <label className="label" htmlFor="bankName">
                  Name of Your Bank
                </label>
                <InputText
                  id="bankName"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                  className="p-inputtext-sm"
                />
                {errors.bankName && (
                  <small className="p-error">{errors.bankName}</small>
                )}
              </div>
            </div>

            <div className="flex flex-row gap-4 mb-2">
              <div className="flex flex-column gap-2 currencySelector">
                <label className="label" htmlFor="currency">
                  Select Currency
                </label>
                <Dropdown
                  value={formData.currency}
                  options={currencyData}
                  onChange={handleCurrencyChange}
                  optionLabel="code"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCurrencyTemplate}
                  itemTemplate={currencyOptionTemplate}
                  placeholder="Select currency"
                />
                {errors.currency && (
                  <small className="p-error">{errors.currency}</small>
                )}
              </div>
              <div className="flex flex-column gap-2 countryInput">
                <label className="label" htmlFor="country">
                  Country
                </label>
                <Dropdown
                  value={formData.country}
                  options={countryData}
                  onChange={handleCountryChange}
                  optionLabel="name"
                  className="selectorCountry"
                  filter
                  filterBy="name"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  placeholder="Select country"
                />
                {errors.country && (
                  <small className="p-error">{errors.country}</small>
                )}
              </div>
            </div>

            <div className="flex flex-row gap-4 mb-4">
              <div className="flex flex-column gap-2 branchNumber">
                <label className="label" htmlFor="branchNumber">
                  Branch Number
                </label>
                <InputText
                  id="branchNumber"
                  name="branchNumber"
                  value={formData.branchNumber}
                  onChange={handleNumericChange}
                  className="p-inputtext-sm"
                  maxLength="5"
                />
                {errors.branchNumber && (
                  <small className="p-error">{errors.branchNumber}</small>
                )}
              </div>
              <div className="flex flex-column gap-2 transitNumber">
                <label className="label" htmlFor="transitNumber">
                  Transit Number
                </label>
                <InputText
                  id="transitNumber"
                  name="transitNumber"
                  value={formData.transitNumber}
                  onChange={handleNumericChange}
                  className="p-inputtext-sm"
                  maxLength="5"
                />
                {errors.transitNumber && (
                  <small className="p-error">{errors.transitNumber}</small>
                )}
              </div>

              <div className="flex flex-column gap-2 accountNumber">
                <label className="label" htmlFor="accountNumber">
                  Account Number
                </label>
                <InputText
                  id="accountNumber"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleNumericChange}
                  className="p-inputtext-sm"
                  maxLength="12"
                />
                {errors.accountNumber && (
                  <small className="p-error">{errors.accountNumber}</small>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <Button type="submit">Done, I'm ready for trade!</Button>
            </div>
            <br />
          </div>
        </div>
      </form>
      <Toast ref={toast} />
    </>
  );
}
