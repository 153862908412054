import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Button } from "primereact/button";
import "./loginreg.css";
import { Steps } from "primereact/steps";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/Action/userAction";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

export default function LoginRegister({ mode }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPW, setConfirmPW] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [sms, setSms] = useState(" ");
  const [activeIndex, setActiveIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [firstNextStep, setFirstNextStep] = useState(false);
  const [secondNextStep, setSecondNextStep] = useState(false);
  const [smsMode, setSmsMode] = useState(false);
  const [gotCode, setGotCode] = useState(false);
  const user = useSelector((state) => state.user);

  const items = [
    {
      label: "Personal Info",
    },
    {
      label: "Credential",
    },
    {
      label: "SMS Auth",
    },
  ];

  const loginOrSignup = () => {
    if (mode === "login") {
      // console.log("Login Clicked");
      dispatch(loginUser({ email, password }));
    }
  };

  const getSMSCode = async () => {
    setSmsMode(true);
    const userData = {
      firstname: firstname,
      lastname: lastname,
      areaCode: area,
      phoneNumber: phone,
      email: email,
      username: username,
      password: password,
    };
    try {
      const response = await fetch(`${apiUrl}/user/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        console.log("SMS code sent successfully");
      } else {
        console.error("Failed to send SMS code");
      }
    } catch (error) {
      console.error("Error sending SMS code:", error);
    }
  };

  const verifySMS = async () => {
    try {
      const response = await fetch(`${apiUrl}/verifySMS`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sms }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("SMS Verify Res: ", result);
        if (result.result === "NOMATCH") {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "SMS code is not correct",
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Register Complete!",
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to verify SMS code",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to verify SMS code",
      });
    }
  };

  useEffect(() => {
    if (
      firstname.length &&
      lastname.length &&
      area.length &&
      phone.length &&
      email.length
    ) {
      setFirstNextStep(true);
    } else {
      setFirstNextStep(false);
    }
  }, [firstname, lastname, area, phone, email]);

  useEffect(() => {
    if (username.length && password.length && confirmPW === password) {
      setSecondNextStep(true);
    } else {
      setSecondNextStep(false);
    }
  }, [username, password, confirmPW]);

  useEffect(() => {
    if (sms.length === 6) {
      setGotCode(true);
    }
  }, [sms]);

  useEffect(() => {
    setUsername("");
    setPassword("");
    setConfirmPW("");
    setFirstname("");
    setLastname("");
    setPhone("");
    setArea("");
    setSms("");
    setEmail("");
    setActiveIndex(0);
  }, [mode]);

  return (
    <>
      {mode === "register" && (
        <Steps
          model={items}
          activeIndex={activeIndex}
          onSelect={(e) => setActiveIndex(e.index)}
          readOnly={true}
        />
      )}
      <br />
      <br />
      {mode === "login" && (
        <>
          <div className="card justify-content-center inputLoginWrap">
            <FloatLabel>
              <InputText
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="username">Email</label>
            </FloatLabel>
            {user.error && <p className="err">{user.error}</p>}
            <br />
            <FloatLabel>
              <InputText
                id="password"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="password">Password</label>
            </FloatLabel>
          </div>
          <br />
          <Button onClick={() => loginOrSignup()} className="nextStepBn">
            Login
          </Button>
        </>
      )}
      {mode === "fpassword" && (
        <div className="card justify-content-center inputWrap">
          <FloatLabel>
            <InputText value={sms} onChange={(e) => setSms(e.target.value)} />
            <label>SMS Code</label>
          </FloatLabel>
          <FloatLabel>
            <InputText value={sms} onChange={(e) => setSms(e.target.value)} />
            <label>New password</label>
          </FloatLabel>
          <FloatLabel>
            <InputText value={sms} onChange={(e) => setSms(e.target.value)} />
            <label>Confirmed New Password</label>
          </FloatLabel>
        </div>
      )}
      {mode === "fpassword" && <Button>Reset Password</Button>}
      {mode === "register" && (
        <>
          {activeIndex === 0 && (
            <div className="card justify-content-center inputWrap">
              <FloatLabel>
                <InputText
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <label>First name</label>
              </FloatLabel>

              <FloatLabel>
                <InputText
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
                <label>Last Name</label>
              </FloatLabel>

              <div className="p-inputgroup flex areaCodeField">
                <FloatLabel>
                  <InputText
                    keyfilter="int"
                    value={area}
                    maxLength={4}
                    onChange={(e) => setArea(e.target.value)}
                  />
                  <label>Area</label>
                </FloatLabel>
                <FloatLabel>
                  <InputText
                    keyfilter="int"
                    id="username3"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <label htmlFor="username3">Phone Number</label>
                </FloatLabel>
              </div>
              <div>
                <FloatLabel>
                  <InputText
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>E-mail Address</label>
                </FloatLabel>
              </div>
            </div>
          )}
          {activeIndex === 1 && (
            <div className="card justify-content-center inputWrap">
              <FloatLabel>
                <InputText
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label htmlFor="username">Username</label>
              </FloatLabel>

              <FloatLabel>
                <InputText
                  id="password"
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="password">Password</label>
              </FloatLabel>

              <FloatLabel>
                <InputText
                  id="confirmPassword"
                  value={confirmPW}
                  type="password"
                  onChange={(e) => setConfirmPW(e.target.value)}
                />
                <label htmlFor="confirmPassword">Confirmed Password</label>
              </FloatLabel>
            </div>
          )}
          {activeIndex === 2 && (
            <div className="card justify-content-center inputWrap">
              <FloatLabel>
                <InputText
                  keyfilter="int"
                  value={sms}
                  onChange={(e) => setSms(e.target.value)}
                />
                <label htmlFor="smsCode">SMS Code</label>
              </FloatLabel>
            </div>
          )}
          <br />
          <br />

          <br />
          <br />

          <div className="flex stepBtnWrap">
            {activeIndex > 0 && (
              <Button
                className="nextStepBtn"
                onClick={() => {
                  activeIndex > 0 ? setActiveIndex(activeIndex - 1) : null;
                }}
              >
                Prior Step
              </Button>
            )}
            <Button
              className="nextStepBtn"
              disabled={
                (activeIndex === 0 && !firstNextStep) ||
                (activeIndex === 1 && !secondNextStep) ||
                (smsMode && !gotCode)
              }
              onClick={() => {
                activeIndex < 2
                  ? setActiveIndex(activeIndex + 1)
                  : gotCode
                  ? verifySMS()
                  : getSMSCode();
              }}
            >
              {smsMode
                ? "Submit"
                : activeIndex < 2
                ? "Next Step"
                : "Get Verification Code"}
            </Button>
          </div>
        </>
      )}
      <Toast ref={toast} />
    </>
  );
}
