import "./order.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineSwap } from "react-icons/ai";
import currencyData from "../../Currency/currency.json";
import { UserService } from "../../Service/UserService";
import { getDatabase, ref, onValue } from "firebase/database";
import { useLocation } from "react-router-dom";
import { Tag } from "primereact/tag";
export default function Order() {
  const [loginUser, setLoginUser] = useState(null);
  const props = useLocation();
  const [orders, setOrders] = useState(null);
  const [notId, SetNotId] = useState(null);
  let navigate = useNavigate();
  const backMain = () => {
    navigate("/");
  };
  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_USERKEY))
      setLoginUser(
        JSON.parse(localStorage.getItem(process.env.REACT_APP_USERKEY))
      );
  }, []);
  useEffect(() => {
    if (props) {
      if (props.state?.id) {
        SetNotId(props.state.id);
      }
      console.log(props)
    }
    
  }, [props]);
  const rowClass = (data) => {
    return {
      "bg-primary": notId === data.id,
    };
  };

  useEffect(() => {
    if (loginUser) {
      const db = getDatabase();

      const starCountRef = ref(db, `orders/or${loginUser.id}`);
      onValue(starCountRef, (snapshot) => {
        if (snapshot.exists()) {
          let returnList = [];
          snapshot.forEach((childSnapshot) => {
            returnList.push({
              ...childSnapshot.val(),
              id: childSnapshot.key,
            });
          });
          setOrders(returnList);
        }
      });
    }
  }, [loginUser]);

  const getSeverity = (status) => {
    switch (status) {
      case "unqualified":
        return "danger";

      case "done":
        return "success";

      case "new":
        return "info";

      case "negotiation":
        return "warning";

      case "renewal":
        return null;
    }
  };

  const transId = (data) => {
    return <p>{data.id}</p>;
  };
  const currencyPair = (data) => {
    return (
      <div className="flex flex-row flagsExch">
        <img
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flagSetup flag flag-${currencyData
            .find((e) => e.code === data.from)
            .countryCode.toLowerCase()}`}
        />
        <div class="item iconWrap">
          <AiOutlineSwap id="swapIcon" className="biIcon" />
        </div>
        <img
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flagSetup flag flag-${currencyData
            .find((e) => e.code === data.to)
            .countryCode.toLowerCase()}`}
        />
      </div>
    );
  };

  const buyyerTem = (data) => {
    return (
      <div className="flex flex-row gap-2">
        <div className="flex align-items-center ">
          <img
            src={UserService.getData().find((e) => e.id == data.buyer).ava}
            width="32"
          />

          <span style={{ marginTop: "8px", marginLeft: "10px" }}>
            {UserService.getData().find((e) => e.id == data.buyer).name}
          </span>
        </div>
        <p className="buyingLab">
          sell {data.buyerAmount.toFixed(2)} {data.from}, buy{" "}
          {data.sellterAmounts.toFixed(2)} {data.to}
        </p>
      </div>
    );
  };
  const sellerTem = (data) => {
    return (
      <div className="flex flex-row gap-2">
        <div className="flex align-items-center ">
          <img
            src={UserService.getData().find((e) => e.id == data.seller).ava}
            width="32"
          />

          <span style={{ marginTop: "8px", marginLeft: "10px" }}>
            {UserService.getData().find((e) => e.id == data.seller).name}
          </span>
        </div>
        <p className="sellingLab">
          sell {data.sellterAmounts.toFixed(2)} {data.to}, buy{" "}
          {data.buyerAmount.toFixed(2)} {data.from}
        </p>
      </div>
    );
  };
  const time = (data) => {
    return <p>UTC: {data.time}</p>;
  };
  const status = (data) => {
    return <Tag value={data.status} severity={getSeverity(data.status)} />;
  };

  return (
    <div className="orderPageWrap">
      <Button
        onClick={() => {
          backMain();
        }}
        text
      >
        <BiArrowBack />{" "}
      </Button>{" "}
      <DataTable
        rowClassName={rowClass}
        value={orders}
        paginator
        rows={10}
        dataKey="id"
        emptyMessage="No orders found."
      >
        <Column
          header="Transaction ID"
          style={{ minWidth: "14rem" }}
          body={transId}
        />
        <Column
          header="Currency Exchange"
          style={{ minWidth: "14rem" }}
          body={currencyPair}
        />
        <Column
          header="Buying"
          body={buyyerTem}
          style={{ minWidth: "10rem" }}
        />
        <Column
          header="Selling"
          style={{ minWidth: "12rem" }}
          body={sellerTem}
        />
        <Column header="Time" style={{ minWidth: "12rem" }} body={time} />
        <Column header="Status" style={{ minWidth: "12rem" }} body={status} />
      </DataTable>
    </div>
  );
}
