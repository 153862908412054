import axios from "axios";
import {
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_ERROR,
  PURCHASE_SUCCESS,
  PURCHASE_ERROR,
} from "../const";

const apiUrl = process.env.REACT_APP_API_URL;

// Action creators for posts
export const fetchPostsSuccess = (posts) => ({
  type: FETCH_POSTS_SUCCESS,
  payload: posts,
});

export const fetchPostsError = (error) => ({
  type: FETCH_POSTS_ERROR,
  payload: error,
});

// Purchase action creators
export const purchaseSuccess = (purchaseData) => ({
  type: PURCHASE_SUCCESS,
  payload: purchaseData,
});

export const purchaseError = (error) => ({
  type: PURCHASE_ERROR,
  payload: error,
});

export const fetchPosts = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(`${apiUrl}/trade/getAllTrades`);
    console.log("Fetched posts: ", response);
    const posts = response.data.filter((post) => post.user_id !== userId) || [];
    dispatch(fetchPostsSuccess(posts));
  } catch (error) {
    console.error("Failed to fetch posts:", error);
    dispatch(fetchPostsError("Failed to fetch posts."));
  }
};

export const makePurchase =
  (purchaseData, buyerEmail, id) => async (dispatch) => {
    const finalAmount =
      purchaseData.amountBuy * purchaseData.rate * 1.029 + 0.3;
    console.log(
      "make phurcahse: ",
      // buyerEmail,
      // purchaseData.postId,
      // purchaseData.selectedCard.id,
      // id,
      // purchaseData.amountBuy,
      purchaseData,
      "finalAmount:",
      finalAmount
    );

    try {
      const response = await axios.get(
        `${apiUrl}/trade/accept?email=${buyerEmail}&tradeId=${purchaseData.postId}&cardId=${purchaseData.selectedCard.id}&bankAccountId=${purchaseData.selectedAccount.bankAccountId}&userId=${id}&finalAmount=${finalAmount}`
      );

      console.log("buy res: ", response);
      // if (response.data.success) {
      //   console.log("Purchase successful: ", response.data);
      //   dispatch(purchaseSuccess(response.data));
      // } else {
      //   console.error("Purchase failed: ", response.data.message);
      //   dispatch(purchaseError(response.data.message));
      // }
    } catch (error) {
      console.error("Network error during purchase: ", error);
      dispatch(purchaseError("Network error during purchase."));
    }
  };
