import {
  LOGIN,
  LOGOUT,
  USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_ORDER_HISTORY_ERROR,
} from "../const";

// Initial state
const iniState = { loading: false, user: { login: false }, error: null };

// User reducer function
const userReducer = (state = iniState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: { ...state.user, login: true, data: action.payload },
        loading: false,
        error: null,
      };
    case LOGOUT:
      return {
        ...state,
        user: { ...state.user, login: false },
        loading: false,
        error: null,
      };
    case USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: { ...state.user, data: action.payload },
        error: null,
      };
    case FETCH_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orderHistory: action.payload,
        error: null,
      };
    case FETCH_ORDER_HISTORY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
