import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import currencyData from "../../Currency/currency.json";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import "./forexsetup.css";

const ForexSetupModal = forwardRef(
  ({ visible, setVisible, orderData, onSubmit }, ref2) => {
    const loginUser = useSelector((state) => state.user.user.data);
    const toast = useRef(null);
    const [max, setMax] = useState(0);
    const [fromCurrency, setFromCurrency] = useState(null);
    const [toCurrency, setToCurrency] = useState(null);
    const [rate, setRate] = useState(0);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    // console.log("forex modal", loginUser);

    const currencyOptionsFrom = loginUser?.currency?.from || [];
    const currencyOptionsTo = loginUser?.currency?.to || [];

    useImperativeHandle(ref2, () => ({
      reset() {
        setMax(0);
        setFromCurrency(null);
        setToCurrency(null);
        setRate(0);
      },
    }));

    useEffect(() => {
      if (orderData) {
        setMax(orderData.amount);
        setFromCurrency(orderData.primary_currency);
        setToCurrency(orderData.secondary_currency);
        setRate(orderData.rate);
      } else {
        setMax(0);
        setFromCurrency(currencyOptionsFrom[0]);
        setToCurrency(currencyOptionsTo[0]);
        setRate(0);
      }
    }, [orderData, currencyOptionsFrom, currencyOptionsTo]);

    const handleSubmit = async () => {
      if (fromCurrency === toCurrency) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "You cannot exchange between the same currency",
        });
      } else if (max > 0 && fromCurrency && toCurrency) {
        const orderPayload = {
          from: fromCurrency,
          to: toCurrency,
          rate,
          max,
          selectedCard,
          selectedAccount,
          ...(orderData && { id: orderData.id }),
        };
        await onSubmit(orderPayload);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Order has been successfully processed!",
        });
        setVisible(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill out all fields before submitting",
        });
      }
    };

    const countryOptionTemplate = (option) => (
      <div className="flex align-items-center">
        <img className="flagImg" src={option.flag} alt={option.code} />
        <div>{option.code}</div>
      </div>
    );

    const selectedCountryTemplate = (option, props) =>
      option ? (
        <div className="flex align-items-center">
          <img alt={option.code} src={option.flag} className="flagImg" />
          <div>{option.code}</div>
        </div>
      ) : (
        <span>{props.placeholder}</span>
      );

    // console.log("forexmodal: ", loginUser.bankingInfo);
    return (
      <div className="card flex justify-content-center">
        <Toast ref={toast} />
        <Dialog
          visible={visible}
          onHide={() => setVisible(false)}
          className="setUpCurDia"
        >
          {orderData ? (
            <>
              <div className="flex flex-row gap-2 wrapBuySellCurSetup">
                <span>sell</span>
                <Dropdown
                  value={currencyData.find((e) => e.code === fromCurrency)}
                  options={currencyData}
                  optionLabel="name"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  disabled
                />
                <span>buy</span>
                <Dropdown
                  value={currencyData.find((e) => e.code === toCurrency)}
                  options={currencyData}
                  optionLabel="name"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  disabled
                />
              </div>
              <br />
              <div className="flex flex-row gap-2 maxValAllowed">
                <label>
                  How much {fromCurrency} do you want to sell in total?
                </label>
                <InputNumber
                  value={max}
                  onChange={(e) => setMax(e.value)}
                  minFractionDigits={2}
                  maxFractionDigits={5}
                  id="maxAmount"
                  aria-describedby="maxAmount-help"
                  className="rateAmount"
                />
              </div>
              <br />
              <div className="flex flex-row gap-2 wrapBuySellCurSetup">
                <p>Your selling price would be 1 {fromCurrency} for </p>
                <InputNumber
                  value={rate}
                  onChange={(e) => setRate(e.value)}
                  minFractionDigits={2}
                  maxFractionDigits={5}
                  id="rate"
                  aria-describedby="rate-help"
                  className="rateAmount"
                />
                <p> {toCurrency}</p>
              </div>
              <br />
              <Button onClick={handleSubmit}>Submit</Button>
            </>
          ) : (
            <>
              <div className="flex flex-row gap-2 wrapBuySellCurSetup">
                <span>sell</span>
                <Dropdown
                  value={currencyData.find((e) => e.code === fromCurrency)}
                  options={currencyOptionsFrom.map((code) =>
                    currencyData.find((e) => e.code === code)
                  )}
                  onChange={(e) => setFromCurrency(e.value.code)}
                  optionLabel="code"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  placeholder="Select currency"
                />
                <span>buy</span>
                <Dropdown
                  value={currencyData.find((e) => e.code === toCurrency)}
                  options={currencyOptionsTo.map((code) =>
                    currencyData.find((e) => e.code === code)
                  )}
                  onChange={(e) => setToCurrency(e.value.code)}
                  optionLabel="code"
                  className="selectorCurr"
                  filter
                  filterBy="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  placeholder="Select currency"
                />
              </div>
              <br />
              <div className="flex flex-row gap-2 maxValAllowed">
                <label>
                  How much {fromCurrency || "currency"} do you want to sell in
                  total?
                </label>
                <InputNumber
                  value={max}
                  onChange={(e) => setMax(e.value)}
                  minFractionDigits={2}
                  maxFractionDigits={5}
                  id="maxAmount"
                  aria-describedby="maxAmount-help"
                  className="rateAmount"
                />
              </div>
              <br />
              <div className="flex flex-row gap-2 wrapBuySellCurSetup">
                <p>
                  Your selling price would be 1 {fromCurrency || "currency"} for{" "}
                </p>
                <InputNumber
                  value={rate}
                  onChange={(e) => setRate(e.value)}
                  minFractionDigits={2}
                  maxFractionDigits={5}
                  id="rate"
                  aria-describedby="rate-help"
                  className="rateAmount"
                />
                <p> {toCurrency || "currency"}</p>
              </div>
              <br />
              <div className="flex flex-row gap-2 wrapBuySellCurSetup">
                <span>Payment Card</span>
                <Dropdown
                  value={selectedCard}
                  options={loginUser.bankingInfo?.paymentCards || []}
                  onChange={(e) => setSelectedCard(e.value)}
                  optionLabel="last4"
                  className="selectorCurr"
                  filter
                  filterBy="name"
                  placeholder="Payment card"
                />
              </div>
              <br />
              <div className="flex flex-row gap-2 wrapBuySellCurSetup">
                <span>Deposit Account</span>
                <Dropdown
                  value={selectedAccount}
                  options={loginUser.bankingInfo?.depositAccounts || []}
                  onChange={(e) => setSelectedAccount(e.value)}
                  optionLabel="accountNumber"
                  className="selectorCurr"
                  filter
                  filterBy="accountName"
                  placeholder="Deposit account"
                />
              </div>
              <br />
              <Button onClick={handleSubmit}>Submit</Button>
            </>
          )}
        </Dialog>
      </div>
    );
  }
);

export default ForexSetupModal;
