import React, { useState, useEffect, useRef } from "react";
import { InputNumber } from "primereact/inputnumber";
import { AiOutlineSwap } from "react-icons/ai";
import "./quoteDialog.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import { getDatabase, ref, set, get, child } from "firebase/database";
import { Toast } from "primereact/toast";
import { v4 as uuidv4 } from "uuid";
export default function QuoteDialogDetails({
  from,
  to,
  fromCode,
  toCode,
  amounts,
  agent,
  user,
  rate,
  setVisible,
}) {
  const [activeIndexStep, setActiveIndexStep] = useState(0);
  const [activeIndexPayment, setActiveIndexPayment] = useState(0);
  const [amount, setAmount] = useState(amounts);
  const [cardNum, setCardNum] = useState("9723972309229911");
  const [edate, setEdate] = useState("05/29");
  const [ccv, setCcv] = useState("412");
  const [err, setErr] = useState(null);
  const toast = useRef(null);
  const db = getDatabase();
  const dbRef = ref(db);
  const checkBalance = async () => {
    let sellerBalance = 0;
    let buyerBalance = 0;
    const snapshotBuyer = await get(
      child(dbRef, `balance/ba${user.id}/${from}`)
    );
    if (!snapshotBuyer.val()) {
      console.log(snapshotSeller.val(),amount)
      setErr(`You don't have enough balance, 0 ${from} left!`);
      setActiveIndexStep(0);
      return;
    }
    if (snapshotBuyer.val() && snapshotBuyer.val() < amount) {
     
      setErr(
        `You don't have enough balance!, Only ${snapshotBuyer
          .val()
          .toFixed(2)} ${from} left!`
      );
      setActiveIndexStep(0);
      return;
    }
 
    buyerBalance = snapshotBuyer.val().toFixed(2);
    const snapshotSeller = await get(
      child(dbRef, `balance/ba${agent.id}/${to}`)
    );
 
    if (!snapshotSeller.val()) {
      setErr(`Seller don't have enough balance, 0 ${to} left!`);
      setActiveIndexStep(0);
      return;
    }
    if (snapshotSeller.val() && snapshotSeller.val() < amount * rate) {
      setErr(
        `You don't have enough balance!, Only ${snapshotBuyer
          .val()
          .toFixed(2)} ${from} left!`
      );
      setActiveIndexStep(0);
      return;
    }
    sellerBalance = snapshotSeller.val().toFixed(2);
    const uuid = uuidv4();
    let datetimeLocal = new Date();
    let strUTC = datetimeLocal.toISOString();
    let transObj = {
      status: "done",
      from,
      to,
      buyer: user.id,
      seller: agent.id,
      time: strUTC,
      buyerAmount: parseFloat(amount),
      sellterAmounts: parseFloat((amount / rate).toFixed(2)),
    };
 
    set(ref(db, `orders/or${user.id}/${uuid}`), transObj);
    set(ref(db, `orders/or${agent.id}/${uuid}`), transObj);
    set(
      ref(db, `balance/ba${user.id}/${from}`),
      parseFloat((buyerBalance - amount).toFixed(2))
    );
    set(
      ref(db, `balance/ba${agent.id}/${to}`),
      parseFloat((sellerBalance - (amount / rate)).toFixed(2))
    );
 
    const snapbuyerOppBa = await get(
      child(dbRef, `balance/ba${user.id}/${to}`)
    );
    const snapsellerOppBa = await get(
      child(dbRef, `balance/ba${agent.id}/${from}`)
    );
    let amountBuyerOp = parseFloat((amount / rate).toFixed(2));
    if (snapbuyerOppBa.val()) {
      amountBuyerOp += parseFloat(snapbuyerOppBa.val().toFixed(2));
    }
 
    let amountSellter = parseFloat(amount.toFixed(2));
    if (snapsellerOppBa.val()) {
      amountSellter += parseFloat(snapsellerOppBa.val().toFixed(2));
    }
 
    set(
      ref(db, `balance/ba${user.id}/${to}`),
      parseFloat(amountBuyerOp.toFixed(2))
    );
    set(
      ref(db, `balance/ba${agent.id}/${from}`),
      parseFloat(amountSellter.toFixed(2))
    );
 
    set(ref(db, `notification/no${user.id}/${uuid}`), {
      id: uuid,
      unread: true,
      note: `Successfylly buy ${parseFloat((amount / rate).toFixed(2))} ${to}.`,
    });
    set(ref(db, `notification/no${agent.id}/${uuid}`), {
      id: uuid,
      unread: true,
      note: `Successfylly buy ${parseFloat(amount.toFixed(2))} ${from}.`,
    });
    toast.current.show({
      severity: "success",
      summary: "",
      detail: "Successfully deposit your wallet!",
    });
    setTimeout(function(){setVisible(false);},3000)
   
 
  };
 
  const submitOrder = () => {
    checkBalance();
  };
  useEffect(() => {
    console.log("here");
    setErr(null);
  }, []);
  return (
    <>
      {agent && activeIndexStep === 0 && (
        <>
          <div className="flex flex-column align-items-center ">
            <img src={agent.ava} width="80" />
            <span style={{ marginTop: "8px", marginLeft: "10px" }}>
              Agent:{agent.name}
            </span>
          </div>
          <br />
          <div className="flex align-items-center gap-2 countryExchange">
            <img
              alt="flag"
              src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
              className={`flag flag-${fromCode.toLowerCase()}`}
              style={{ width: "24px" }}
            />
            <span>{from}</span>
            <AiOutlineSwap />
            <img
              alt="flag"
              src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
              className={`flag flag-${toCode.toLowerCase()}`}
              style={{ width: "24px" }}
            />
            <span>{to}</span>
          </div>
          <br />
          <div className="inputLabelWrap">
            <label htmlFor="username">Amounts to pay</label>
            <InputNumber
              id="username"
              value={amount}
              onChange={(e) => setAmount(e.value)}
              prefix="$"
              suffix={` ${from}`}
            />
          </div>
          <p className="err">{err}</p>
          <br />
          <h4>
            You will get ${(amount / rate).toFixed(2)} {to}
          </h4>
          <br />
 
          <div className="flex flex-column align-items-center "></div>
        </>
      )}
      {agent && activeIndexStep === 1 && (
        <>
          <TabView
            activeIndex={activeIndexPayment}
            onTabChange={(e) => setActiveIndexPayment(e.index)}
          >
            <TabPanel header="Credit Card">
              <br />
              <div className="flex flex-row gap-2">
                <label>Card Number</label>
                <InputText
                  id="username"
                  aria-describedby="username-help"
                  value={cardNum}
                  onChange={(e) => setCardNum(e.target.value)}
                />
              </div>
              <br />
              <div className="flex flex-row gap-2">
                <div className="flex flex-row gap-2 cvcQuote">
                  <label htmlFor="username">CVC</label>
                  <InputText
                    id="username"
                    aria-describedby="username-help"
                    value={ccv}
                    onChange={(e) => setCcv(e.target.value)}
                  />
                </div>
                <div className="flex flex-row gap-2 exDate">
                  <label htmlFor="username">Expired Date</label>
                  <InputText
                    id="username"
                    aria-describedby="username-help"
                    value={edate}
                    onChange={(e) => setEdate(e.target.value)}
                  />
                </div>
              </div>
 
              <br />
              <div className="flex flex-row gap-2 address">
                <label>Address</label>
                <InputText />
              </div>
            </TabPanel>
            <TabPanel header="Etransfer">
              <p className="m-0">
                Step1: Add email address dennis.da.xu@gmail.com
              </p>
              <br />
              <p className="m-0">
                Step2: Select Amount of money ${amounts} {from} to send
              </p>
              <br />
              <p className="m-0">
                Step3: Copy paste following on message: account#: 12323451
                amount:${amounts} {from}
              </p>
              <br />
              <p className="m-0">
                Step4: After the agent get the money through E-transfer, you
                will get your exchanged currency in your wallet.
              </p>
            </TabPanel>
          </TabView>
        </>
      )}
      <br />
      <div className="flex flex-row gap-2 submitNextBtn">
        <Button
          onClick={() =>
            setActiveIndexStep(
              activeIndexStep === 0 ? activeIndexStep + 1 : activeIndexStep - 1
            )
          }
        >
          {activeIndexStep === 0 ? "Next" : "Prior Step"}
        </Button>
        {activeIndexStep === 1 && activeIndexPayment === 0 && (
          <Button
            onClick={() => {
              submitOrder();
            }}
            disabled={!ccv || !edate || !cardNum}
          >
            Submit
          </Button>
        )}
      </div>
      <Toast ref={toast} />
    </>
  );
}