import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import currencyData from "../../Currency/currency.json";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux"; // Import Redux hooks
import { makePurchase } from "../../Redux/Action/postAction"; // Import the purchase action
import "./buyPage.css";

const BuyModal = forwardRef(({ visible, setVisible, pairSet }, ref2) => {
  // console.log("buymodel: ", pairSet);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.user.user.data);
  const [max, setMax] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useImperativeHandle(ref2, () => ({
    reset() {
      setMax(0);
    },
  }));

  const handlePurchase = async () => {
    // console.log("handleP: ", loginUser);
    if (pairSet.amount > 0) {
      const purchaseData = {
        postId: pairSet.id,
        amountBuy: pairSet.amount,
        rate: pairSet.rate,
        selectedCard,
        selectedAccount,
      };
      // console.log("purchase data: ", purchaseData);
      if (!loginUser) {
        toast.current.show({
          severity: "error",
          summary: "User not logged in",
          detail: "Please log in to make a purchase",
        });
        return;
      }

      dispatch(makePurchase(purchaseData, loginUser.email, loginUser.id))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Purchase has been successfully made!",
          });
          setVisible(false);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Purchase Failed",
            detail: error,
          });
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill out all fields before submitting",
      });
    }
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img className="flagImg" src={option.flag} alt={option.code} />
        <div>{option.code}</div>
      </div>
    );
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.code} src={option.flag} className="flagImg" />
          <div>{option.code}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        className="setUpCurDia"
      >
        <div className="flex flex-row gap-2 wrapBuySellCurSetup">
          <span>buy</span>
          <Dropdown
            value={
              pairSet
                ? currencyData.find((e) => e.code === pairSet.primary_currency)
                : null
            }
            options={currencyData}
            optionLabel="name"
            className="selectorCurr"
            filter
            filterBy="code"
            valueTemplate={selectedCountryTemplate}
            itemTemplate={countryOptionTemplate}
            disabled
          />
          <span>sell</span>
          <Dropdown
            value={
              pairSet
                ? currencyData.find(
                    (e) => e.code === pairSet.secondary_currency
                  )
                : null
            }
            options={currencyData}
            optionLabel="name"
            className="selectorCurr"
            filter
            filterBy="code"
            valueTemplate={selectedCountryTemplate}
            itemTemplate={countryOptionTemplate}
            disabled
          />
        </div>
        <br />
        {pairSet && (
          <div className="flex flex-row gap-2 maxValAllowed">
            <label>
              How much {pairSet.secondary_currency} do you want to buy in total?
            </label>
            <InputNumber
              value={pairSet.amount}
              // onChange={(e) => setMax(e.value)}
              minFractionDigits={2}
              maxFractionDigits={5}
              id="maxAmount"
              aria-describedby="maxAmount-help"
              className="rateAmount"
              disabled
            />
          </div>
        )}
        <br />
        {pairSet && (
          <div className="flex flex-row gap-2 wrapBuySellCurSetup">
            <p>Buying each {pairSet.primary_currency} with </p>
            <InputNumber
              value={pairSet.rate * 1.15}
              minFractionDigits={2}
              maxFractionDigits={5}
              id="rate"
              aria-describedby="rate-help"
              className="rateAmount"
              disabled
            />
            <p> {pairSet.secondary_currency} fees incl.</p>
          </div>
        )}
        <br />
        <div className="flex flex-row gap-2 wrapBuySellCurSetup">
          <span>Payment Card</span>
          <Dropdown
            value={selectedCard}
            options={loginUser.bankingInfo?.paymentCards || []}
            onChange={(e) => setSelectedCard(e.value)}
            optionLabel="last4"
            className="selectorCurr"
            filter
            filterBy="name"
            placeholder="Payment card"
          />
        </div>
        <br />
        <div className="flex flex-row gap-2 wrapBuySellCurSetup">
          <span>Deposit Account</span>
          <Dropdown
            value={selectedAccount}
            options={loginUser.bankingInfo?.depositAccounts || []}
            onChange={(e) => setSelectedAccount(e.value)}
            optionLabel="accountNumber"
            className="selectorCurr"
            filter
            filterBy="accountName"
            placeholder="Deposit account"
          />
        </div>
        <br />
        <Button onClick={handlePurchase}>Buy</Button>
      </Dialog>
    </div>
  );
});

export default BuyModal;
