export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_ERROR = "USER_ERROR";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

// Order Actions
export const FETCH_MY_ORDERS_SUCCESS = "FETCH_MY_ORDERS_SUCCESS";
export const ADD_MY_ORDER = "ADD_MY_ORDER";
export const DELETE_MY_ORDER = "DELETE_MY_ORDER";
export const UPDATE_MY_ORDER = "UPDATE_MY_ORDER";
export const ORDER_ERROR = "ORDER_ERROR";

// Post Actions
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_ERROR = "FETCH_POSTS_ERROR";
export const POST_ERROR = "POST_ERROR";

// Purchase Actions
export const PURCHASE_SUCCESS = "PURCHASE_SUCCESS";
export const PURCHASE_ERROR = "PURCHASE_ERROR";

// New Action Types for Order History
export const FETCH_ORDER_HISTORY_SUCCESS = "FETCH_ORDER_HISTORY_SUCCESS";
export const FETCH_ORDER_HISTORY_ERROR = "FETCH_ORDER_HISTORY_ERROR";
