// profile.js

import React, { useEffect, useRef, useState } from "react";
import "./profile.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProfile,
  deleteCardOrAccount,
} from "../../Redux/Action/userAction";

const CreditCard = ({
  type,
  cardNumber,
  expiryDate,
  country,
  onDelete,
  bank,
}) => (
  <div className="credit-card">
    <Button className="deleteBtn" icon="pi pi-times" onClick={onDelete} />
    <br />
    <p>{cardNumber}</p>
    {expiryDate !== "N/A" && <p>Exp. {expiryDate}</p>}
    {type === "N/A" && <p>{country}</p>}
    {type !== "N/A" ? <p>{`${type}   ${country}`}</p> : <p>{bank}</p>}
  </div>
);

CreditCard.propTypes = {
  type: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  expiryDate: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const Profile = () => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const loginUser = useSelector((state) => state.user.user.data);

  const { error } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    area_code: "",
    phone_number: "",
    address1: "",
    address2: "",
    province: "",
    postal_code: "",
    country: "",
  });

  useEffect(() => {
    if (loginUser) {
      let addressRegistered = false;
      let addressArray = [];
      if (loginUser.address) {
        addressRegistered = true;
        addressArray = loginUser.address.split(",");
      }
      setFormData({
        first_name: loginUser.first_name,
        last_name: loginUser.last_name,
        username: loginUser.username,
        email: loginUser.email,
        area_code: loginUser.area_code,
        phone_number: loginUser.phone_number,
        address1: addressRegistered ? addressArray[0] : "",
        address2: addressRegistered ? addressArray[1] : "",
        province: addressRegistered ? addressArray[2] : "",
        postal_code: addressRegistered ? addressArray[3] : "",
        country: addressRegistered ? addressArray[4] : "",
      });
    }
  }, [loginUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loginUser) {
      dispatch(updateProfile(loginUser.id, formData))
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "",
            detail: "Profile updated successfully!",
          });
        })
        .catch(() => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error || "Failed to update profile",
          });
        });
    }
  };

  const handleDeleteCard = (id, type) => {
    const email = loginUser.email;
    dispatch(deleteCardOrAccount(email, id, type))
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: `${type} Deleted`,
          detail: `${type} has been successfully deleted`,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error || `Failed to delete ${type.toLowerCase()}`,
        });
      });
  };

  if (!loginUser) {
    return null;
  }

  // console.log("profile formdata: ", formData, loginUser);
  // console.log("profile banking: ", loginUser.bankingInfo);
  return (
    <>
      <Button className="backBtn" onClick={() => navigate("/")} text>
        <BiArrowBack />
      </Button>
      <div className="profilePage">
        <div className="profilePageContent">
          <div
            className="wallet-container"
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div style={{ display: "flex", gap: "20px" }}>
              <div className="wallet">
                <div className="walletTitle font-bold">Payment Methods</div>
                <div className="cardsContainer">
                  {loginUser?.bankingInfo?.paymentCards?.map((card) => (
                    <CreditCard
                      key={card.id}
                      type={card.brand}
                      cardNumber={`XXXX-${card.last4}`}
                      country={card.country}
                      expiryDate={`${card.expMonth}/${card.expYear}`}
                      onDelete={() => handleDeleteCard(card.id, "Card")}
                    />
                  ))}
                </div>
              </div>
              <div className="wallet">
                <div className="walletTitle font-bold">Deposite Accounts</div>
                <div className="cardsContainer">
                  {loginUser?.bankingInfo?.depositAccounts?.map((card) => (
                    <CreditCard
                      key={card.bankAccountId}
                      type={"N/A"}
                      bank={card.bankName}
                      country={card.country + " " + card.currency}
                      cardNumber={
                        card.branchNumber +
                        card.transitNumber +
                        card.accountNumber
                      }
                      expiryDate={"N/A"}
                      onDelete={() =>
                        handleDeleteCard(card.bankAccountId, "Account")
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
            <Button
              className="walletButton"
              onClick={() => navigate("/paymentinfo")}
              style={{ alignSelf: "center", marginTop: "10px" }}
              disabled={!loginUser.address}
            >
              {loginUser.address
                ? "Add Banking Info"
                : "Complete Profile Before Adding Banking Info"}
            </Button>
          </div>
          <form className="personalInfo" onSubmit={handleSubmit}>
            <div className="inputRow flexContainer">
              <div className="inputCol halfWidth">
                <label className="label" htmlFor="areaCode">
                  First Name
                </label>
                <InputText
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  aria-describedby="first_name-help"
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div className="inputCol halfWidth">
                <label className="label" htmlFor="phone">
                  Last Name
                </label>
                <InputText
                  id="last_name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  aria-describedby="last_name-help"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="inputContainer billingAddress">
              <label className="label" htmlFor="billingAddress1">
                Username
              </label>
              <InputText
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                aria-describedby="username-help"
                type="text"
                placeholder="Username"
              />
            </div>
            <div className="inputContainer billingAddress">
              <label className="label" htmlFor="billingAddress2">
                Email Address
              </label>
              <InputText
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                aria-describedby="email-help"
                type="email"
                placeholder="Email"
                disabled
              />
            </div>
            <div className="inputRow flexContainer">
              <div className="inputCol thirdWidth">
                <label className="label" htmlFor="areaCode">
                  Area Code
                </label>
                <InputText
                  id="areaCode"
                  name="areaCode"
                  value={formData.area_code}
                  onChange={handleChange}
                  maxLength="3"
                  className="p-inputtext-sm"
                />
                {errors.areaCode && (
                  <small className="p-error">{errors.areaCode}</small>
                )}
              </div>
              <div className="inputCol phoneNumber">
                <label className="label" htmlFor="phone">
                  Phone Number
                </label>
                <InputText
                  id="phone"
                  name="phone"
                  value={formData.phone_number}
                  onChange={handleChange}
                  maxLength="10"
                  className="p-inputtext-sm"
                />
                {errors.phone_number && (
                  <small className="p-error">{errors.phone_number}</small>
                )}
              </div>
            </div>
            <div className="inputContainer billingAddress">
              <label className="label" htmlFor="billingAddress1">
                Billing Address Line 1
              </label>
              <InputText
                id="address1"
                name="address1"
                value={formData.address1}
                onChange={handleChange}
                className="p-inputtext-sm"
              />
            </div>
            <div className="inputContainer billingAddress">
              <label className="label" htmlFor="billingAddress2">
                Billing Address Line 2
              </label>
              <InputText
                id="address2"
                name="address2"
                value={formData.address2}
                onChange={handleChange}
                className="p-inputtext-sm"
              />
            </div>
            <div className="inputRow flexContainer">
              <div className="inputCol thirdWidth">
                <label className="label" htmlFor="province">
                  Province
                </label>
                <InputText
                  id="province"
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                  className="p-inputtext-sm"
                />
              </div>
              <div className="inputCol thirdWidth">
                <label className="label" htmlFor="postalCode">
                  Postal Code
                </label>
                <InputText
                  id="postal_code"
                  name="postal_code"
                  value={formData.postal_code}
                  onChange={handleChange}
                  className="p-inputtext-sm"
                />
                {errors.postalCode && (
                  <small className="p-error">{errors.postalCode}</small>
                )}
              </div>
              <div className="inputCol thirdWidth">
                <label className="label" htmlFor="country">
                  Country
                </label>
                <InputText
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="p-inputtext-sm"
                />
                {errors.country && (
                  <small className="p-error">{errors.country}</small>
                )}
              </div>
            </div>
            <div className="buttons flex flex-row gap-2">
              <Button onClick={() => navigate("/idcheck")}>
                Verify My IDs
              </Button>
              {/* <Button type="button">Edit</Button> */}
              <Button type="submit">Update</Button>
            </div>
          </form>
        </div>
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default Profile;
